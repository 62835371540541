<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">客户中心</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">企业单位审核</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">

            <div title="企业名称" class="searchboxItem ci-full">
              <span class="itemLabel">企业名称:</span>
              <el-input
                  v-model="compName"
                  type="text"
                  size="small"
                  placeholder="请输入企业名称"
                  clearable
              />
            </div>
            <div title="信用代码" class="searchboxItem ci-full">
              <span class="itemLabel">信用代码:</span>
              <el-input
                  v-model="compNo"
                  type="text"
                  size="small"
                  placeholder="请输入信用代码"
                  clearable
              />
            </div>
            <div title="认证状态" class="searchboxItem ci-full">
              <span class="itemLabel">认证状态:</span>
              <el-select
                  v-model="authState"
                  placeholder="请选择"
                  size="small"
                  clearable
              >
                <el-option
                    v-for="item in authStateList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button
                class="bgc-bv"
                style="margin: 0 10px"
                round
                @click="getData()"
            >查询</el-button
            >
          </div>

        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  :index="indexMethod"
              />
              <el-table-column
                  label="企业名称"
                  align="left"
                  prop="compName"
                  show-overflow-tooltip
                  min-width="240"
              />
              <el-table-column
                  label="信用代码"
                  align="left"
                  prop="compNo"
                  show-overflow-tooltip
                  min-width="240"
              />
              <el-table-column
                  label="所在城市"
                  align="left"
                  prop="areaName"
                  min-width="180"
              />
              <el-table-column
                  label="公司类型"
                  align="left"
                  prop="hrCompType"
                  min-width="120"
              >
                <template slot-scope="{ row }">
                  {{ row.hrCompType?$setDictionary("HR_COMP_TYPE", row.hrCompType):'--' }}
                </template>
              </el-table-column>
              <el-table-column
                  label="公司规模"
                  align="left"
                  prop="hrCompScale"
                  min-width="120"
              >
                <template slot-scope="{ row }">
                  {{ row.hrCompScale?$setDictionary("HR_COMP_SCALE", row.hrCompScale):'--' }}
                </template>
              </el-table-column>
              <el-table-column
                  label="所属行业"
                  align="left"
                  prop="compIndustry"
                  min-width="240"
              >
                <template slot-scope="{ row }">
                  {{ row.compIndustry?$setDictionary("INDUSTRY", row.compIndustry):'--' }}
                </template>
              </el-table-column>
              <el-table-column
                  label="地址"
                  align="left"
                  prop="compAddress"
                  min-width="180"
              >
                <template slot-scope="{ row }">
                  {{row.compAddress?row.compAddress:'--'}}
                </template>
              </el-table-column>
              <el-table-column
                  label="认证状态"
                  align="left"
                  prop="authState"
                  min-width="120"
              >
                <template slot-scope="{ row }">
                  {{ row.authState?$setDictionary("HR_COMP_AUTH", row.authState):'--' }}
                </template>
              </el-table-column>
              <el-table-column
                  label="认证时间"
                  align="left"
                  prop="authTime"
                  min-width="160"
              >
                <template slot-scope="{ row }">
                  {{ row.authTime?row.authTime:'--' }}
                </template>
              </el-table-column>
              <el-table-column
                  label="备注"
                  align="left"
                  prop="authReason"
                  min-width="180"
              >
                <template slot-scope="{ row }">
                  {{row.authReason?row.authReason:'--'}}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="160px" fixed="right">
                <div slot-scope="scope" class="flexcc">

                  <el-button
                      type="text"
                      :style="scope.row.authState!='20'?'padding: 0px 5px':'padding: 0px 5px;color:red'"
                      size="mini"
                      @click="handleEdit('audit',scope.row.compId)"
                      :disabled="scope.row.authState=='30'||scope.row.authState=='10'"
                  >审核</el-button
                  >
                  <el-button
                      type="text"
                      :style="scope.row.authState!='20'?'padding: 0px 5px':'padding: 0px 5px;color:red'"
                      size="mini"
                      @click="handleEdit('view',scope.row.compId)"
                  >查看</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "UnitListAudit",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      compName: "",
      compNo: "",
      authState: "",
      authStateList:[],
      search: {
        saleName: true,
      },


    };
  },
  created() {
    this.getAuthStateList();
  },
  computed: {},
  methods: {

    // 认证状态 - 下拉数据
    getAuthStateList() {
      console.log(this.$setDictionary("HR_COMP_AUTH", "list"))
      const authStateList = this.$setDictionary("HR_COMP_AUTH", "list");
      let authStatelist = [{ value: "", label: "全部" }];
      for (const key in authStateList) {
        authStatelist.push({
          value: key,
          label: authStateList[key],
        });
      }
      this.authStateList = authStatelist;
    },
    // 初始化获取列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.compName) {
        params.compName = this.compName;
      }
      if (this.compNo) {
        params.compNo = this.compNo;
      }
      if (this.authState) {
        params.authState = this.authState;
      }

      this.doFetch({
        url: "/hr/company/audit/pageList",
        params,
        pageNum,
      });
    },
    // 单位编辑
    handleEdit(stu,compId) {
      this.$router.push({
        path: "/web/UnitEditAudit",
        query: { compId, stu},
      });
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight =
          window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },

  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.compName = ""),
          (this.pageNum = 1);
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.upload-btn {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
.searchbox {
  padding: 0;
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background-color: transparent;
}

.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.btnBox {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}
.docsDialog {
  .el-form-item {
    margin-bottom: 0.5rem;
  }
}
//.exportdialog {
.upload-workers {
  height: 30px;
  .el-upload {
    height: 30px !important;
    width: 97px;
    border: none !important;
    .el-button {
      padding: 5px 0;
    }
  }
}
//}
.studentNum {
  margin: 0 0 10px;
  span {
    margin: 0 10px;
  }
}
</style>